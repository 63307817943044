import React from 'react'
import Layout from '../components/Layout'
import CallBackBanner from '../components/CallBackBanner'
import PromotionBanner from '../components/PromotionBanner/PromotionBanner'
import GeneralEnquiryForm from '../components/shared/Form/GeneralEnquiryForm'
import img from '../images/industry-sector/team_industry.svg'
import IndustryTemplateLayout from '../components/IndustrySector/IndustryTemplateLayout'
import MainGrid from '../components/styling/MainGrid'
import Breadcrumb from '../components/shared/Breadcrumb/Breadcrumb'
import IndustryLeftCol from '../components/IndustrySector/IndustryLeftCol'
import IndustryRightCol from '../components/IndustrySector/IndustryRightCol'
import IndustryDescription from '../components/IndustrySector/IndustryDescription'
import IndustryContent from '../components/IndustrySector/IndustryContent'

const breadcrumbData = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'Industry Sector',
    url: '/industry-sector',
  },
  {
    title: 'Aerospace and Defence',
    url: '/aerospace-and-defence',
  },
]

const IndustrySector = () => {
  return (
    <Layout
      activeLink="/industry-sector"
      title="R&D Aerospace and Defence"
      description="R&D Aerospace and Defence"
    >
      <MainGrid noPaddingMobile noPaddingTablet>
        <Breadcrumb data={breadcrumbData} />
      </MainGrid>
      <IndustryTemplateLayout>
        <IndustryLeftCol
          category="Qualifying industry sectors"
          title="Aerospace and Defence"
          backLink="/industry-sector"
        />
        <IndustryRightCol>
          <IndustryDescription title="Aerospace and Defence" />
          <IndustryContent
            heading="Do your projects involve physical testing or prototyping to examine, for instance, aerodynamism, impact resistance or strength?"
            paragraph="Do your projects involve high quality type material finishes? Do you design bespoke products for the aerospace industry using different types of materials? Are you using existing design processes for manufacturing techniques on the project but in a new or novel way? Do your projects involve physical testing or prototyping for instance impact resistance and strength?"
            content={[
              <div>
                <h6>We can help you identify your R&D</h6>
                <p>
                  No one knows your R&D like you do, but sometimes it is tough
                  to explain. That is why at RDA, we do not consider
                  ourselves only to be tax experts — we are communicators too.
                </p>
                <p>
                  Your tax-relief submission cannot be a simple matter of
                  form-filling. Only by truly understanding the projects you
                  undertake can we convey the true value of your R&D in the
                  language HMRC understands. That is why we pride ourselves on
                  our unique, one-to-one consultancy approach. In addition to
                  the preparation and submission of your R&D Tax Credits Scheme
                  claim, with RDA, you will have access to:
                </p>
                <ul>
                  <li>a dedicated Client Manager on call</li>
                  <li>
                    plain-English bulletins on all government R&D regulations
                    and directives
                  </li>
                  <li>a confidential R&D record-keeping service</li>
                  <li>R&D best-practice coaching and workshops</li>
                  <li>Intellectual-property assessment and advocacy</li>
                </ul>
                <p>
                  The purpose of HMRC’s R&D Tax Credits Scheme is to stimulate
                  investment in research and development in the UK economy. We
                  know your R&D is continuously evolving, so that is why we
                  maintain an ongoing, year-round relationship with our partners
                  to work not only on their tax-relief claim but also to help
                  facilitate their R&D itself.
                </p>
                <p>
                  With RDA on hand, you will maximise the returns you
                  get both from your tax-relief application and from your
                  finished R&D.
                </p>
                <p>
                  Develop <i>together</i>. It’s the RDA Way
                </p>
              </div>,
            ]}
          />
        </IndustryRightCol>
      </IndustryTemplateLayout>
      <PromotionBanner />
      <GeneralEnquiryForm />
      <CallBackBanner />
    </Layout>
  )
}

export default IndustrySector
